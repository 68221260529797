<mat-accordion>
  <mat-expansion-panel
    *ngFor="let step of steps; let index = index"
    [disabled]="step.disabled"
    [expanded]="selectedIndex === index"
    (opened)="selectedIndex = index"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <img *ngIf="step.icon" [src]="step.icon" class="mr-4 step-icon" />
        {{ step.title }}
      </mat-panel-title>

      <mat-panel-description class="justify-between mr-0">
        <div>{{ step.description }}</div>
        <ng-container
          *ngIf="step.completed; else failIcon"
          [ngTemplateOutlet]="successIcon"
        ></ng-container>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <ng-container *ngIf="step.templateRef">
      <ng-template [ngTemplateOutlet]="step.templateRef"></ng-template>
    </ng-container>

    <mat-action-row>
      <em-button
        type="text"
        [disabled]="!step.completed"
        (btnClick)="goToStep(index + 1)"
      >
        <ng-container *ngIf="index < steps.length - 1">{{
          'SHARED_LABEL_NEXT' | translate
        }}</ng-container>
        <ng-container *ngIf="index === steps.length - 1">{{
          'SHARED_LABEL_FINISH' | translate
        }}</ng-container>
      </em-button>
    </mat-action-row>
  </mat-expansion-panel>
</mat-accordion>

<ng-template #successIcon>
  <mat-icon class="text-success-500 flex-shrink-0">check_circle</mat-icon>
</ng-template>

<ng-template #failIcon>
  <mat-icon class="text-danger-500 flex-shrink-0">cancel</mat-icon>
</ng-template>
