import {Injectable, inject} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
  IFilterSet,
  IProductGroupTemplate,
  PRODUCTS_GROUP_TEMPLATES,
  ProductGroupType,
  ProductGroupsStore,
  buildSaveRequest,
  duplicateGroup,
} from '@em/data-feed/data-access-products';
import {isNotNullOrUndefined} from '@em/shared/util-rxjs';
import {CountryCode} from '@em/shared/util-types';
import {NotificationService} from '@em/shared/util-web';
import {CountryService} from '@em/user-account/data-access';
import {
  Observable,
  catchError,
  isObservable,
  map,
  merge,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {EditProductGroupComponent} from '../../product-groups/edit-product-group/edit-product-group.component';
import {NewProductGroupDialogComponent} from '../../product-groups/new-product-group-dialog/new-product-group-dialog.component';

@Injectable()
export class ManageGroupsService {
  private readonly _dialog = inject(MatDialog);
  private readonly _productGroupsStore = inject(ProductGroupsStore);
  private readonly _notifications = inject(NotificationService);
  private readonly _countryService = inject(CountryService);

  readonly isAdding$ = this._productGroupsStore.isAdding$;

  openAddEditGroup(filterSet?: IFilterSet): Observable<IFilterSet | null> {
    const dialogRef = this._dialog.open(EditProductGroupComponent, {
      data: {group: filterSet},
      disableClose: true,
    });

    return merge(
      dialogRef.afterClosed().pipe(map(() => null)),
      dialogRef.componentInstance.finish.pipe(tap(() => dialogRef.close())),
    ).pipe(take(1));
  }

  openEditGroupAndSave(
    filterSet?: Partial<IFilterSet>,
    country?: CountryCode,
  ): Observable<string | null> {
    //ToDo: make sure we save the correct country after edit
    const dialogRef = this._dialog.open(EditProductGroupComponent, {
      data: {group: filterSet},
      disableClose: true,
    });

    return merge(
      dialogRef.afterClosed().pipe(map(() => null)),
      dialogRef.componentInstance.finish.pipe(
        switchMap((editedFilterSet) => {
          if (editedFilterSet) {
            // User finish editing
            return this.saveGroup(editedFilterSet, country);
          } else {
            // User cancel editing
            dialogRef.close();
            return of(null);
          }
        }),
        tap(() => dialogRef.close()),
      ),
    ).pipe(take(1));
  }

  openAddGroupAndSave(groupType?: ProductGroupType): Observable<string | null> {
    const dialogRef = this._dialog.open(NewProductGroupDialogComponent, {
      data: {type: groupType},
      disableClose: true,
      width: '400px',
    });

    return merge(
      dialogRef.afterClosed().pipe(map(() => null)),
      dialogRef.componentInstance.save,
    ).pipe(
      take(1),
      switchMap((selectTemplateResult) => {
        if (!selectTemplateResult) {
          return of(null);
        } else {
          const {type, templateName} = selectTemplateResult;
          if (type === 'custom') {
            // Open add custom group
            return this.openEditGroupAndSave(undefined);
          } else if (templateName) {
            // Add from template and emit new added id
            return this.addGroupByTemplateName(templateName);
          } else {
            return of(null);
          }
        }
      }),
      tap(() => dialogRef.close()),
    );
  }

  saveGroup(
    filterSet: IFilterSet,
    country?: CountryCode,
  ): Observable<string | null> {
    return (country ? of(country) : this._countryService.observable()).pipe(
      isNotNullOrUndefined(),
      take(1),
      switchMap((countryCode) => {
        if (!countryCode || !filterSet) {
          throw new Error('Country or Group Missing');
        }

        this._productGroupsStore.addGroup(
          buildSaveRequest(filterSet, countryCode),
        );

        return this._productGroupsStore.groupSaved$.pipe(
          isNotNullOrUndefined(),
          take(1),
        );
      }),
      catchError(() => {
        this._notifications.error('FILTER_SET_SAVE_FAILED');
        return of(null);
      }),
    );
  }

  addGroupByTemplateName(templateName: string, country?: CountryCode) {
    let countryObs$: Observable<CountryCode>;
    if (country) {
      countryObs$ = of(country);
    } else {
      countryObs$ = this._countryService.observable();
    }
    return countryObs$.pipe(
      isNotNullOrUndefined(),
      take(1),
      switchMap((passedCountry) => {
        this._productGroupsStore.addGroupByTemplateName({
          templateName,
          country: passedCountry,
        });
        return this._productGroupsStore.groupSaved$.pipe(
          isNotNullOrUndefined(),
          take(1),
          tap(() => {
            this._notifications.success('PRODUCT_GROUP_ADDED_SUCCESSFULY');
          }),
        );
      }),
    );
  }

  addGroupsFromTemplates(
    country: CountryCode,
    templates: IProductGroupTemplate[],
  ) {
    this._productGroupsStore.addGroupsFromTemplates({country, templates});
  }

  getGroups(
    country?: Observable<CountryCode> | CountryCode,
    forceLoad?: boolean,
  ) {
    let countryObs$: Observable<CountryCode>;
    if (!country) {
      countryObs$ = this._countryService.observable();
    } else if (isObservable(country)) {
      countryObs$ = country;
    } else {
      countryObs$ = of(country);
    }

    return this._productGroupsStore.getGroups(countryObs$, forceLoad);
  }

  getLoadingState(
    country?: Observable<CountryCode> | CountryCode,
  ): Observable<boolean> {
    let countryObs$: Observable<CountryCode>;
    if (!country) {
      countryObs$ = this._countryService.observable();
    } else if (isObservable(country)) {
      countryObs$ = country;
    } else {
      countryObs$ = of(country);
    }

    return this._productGroupsStore.getLoadingState(countryObs$);
  }

  getGroupTemplates(
    country?: Observable<CountryCode> | CountryCode,
    forceLoad?: boolean,
  ): Observable<IProductGroupTemplate[]> {
    return this.getGroups(country, forceLoad).pipe(
      map((groups) =>
        PRODUCTS_GROUP_TEMPLATES.map((temp) => ({
          ...temp,
          isAdded: !!groups.find((g) => g.name === temp.name),
        })),
      ),
    );
  }

  deleteGroup(groupId: string) {
    return this._productGroupsStore.deleteGroup(groupId);
  }

  forceLoadGroups() {
    this._productGroupsStore.loadGroups(
      this._countryService.observable().pipe(take(1)),
    );
  }

  duplicateAndEditGroup(filterSet: IFilterSet) {
    const newGroup = duplicateGroup(filterSet);
    return this.openEditGroupAndSave(newGroup, newGroup.country);
  }
}
